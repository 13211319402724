<script setup lang="ts">
    import type { AccordionContentData, AccordionData, InfoBlockData } from '~/@types/cms';
    import type { IWidgetProps } from '~/composables/widgets';

    /**
     * Asset widget for images, youtube videos or background videos
     * value contains a list of assets (otherwise rendered as gallery)
     * in case of an image, we need an image observer to check if the image is actually in the viewport before we load it
     */

    const props = defineProps<IWidgetProps<AccordionData>>();
    const { data, getValueSequence } = useWidget(props);

    const title = computed(() => {
        if (data?.intro?.title) {
            return data.intro.title;
        }
        return undefined;
    });
    const subtitle = computed(() => {
        if (data?.intro?.subtitle) {
            return data.intro.subtitle;
        }
        return undefined;
    });

    const typedSequence = computed(() => {
        const values = getValueSequence(data?.value);
        if (values.every((v) => !v.types.includes('AccordionContent')))
            return {
                data: values as InfoBlockData[],
                type: 'text',
            } as const;

        return {
            data: values as AccordionContentData[],
            type: 'full',
        } as const;
    });
    const accordionData = computed(() => {
        if (typedSequence.value.type === 'full') return typedSequence.value.data;

        // map textAccordion to fullAccordion
        return typedSequence.value.data.map(({ title, codename, subtitle, id, types, language }) => {
            return {
                id,
                codename,
                types,
                language,
                title,
                page: {
                    content: {
                        value: subtitle || '',
                        components: [],
                        references: [],
                    },
                },
            } satisfies AccordionContentData;
        });
    });
</script>
<template>
    <lazy-org-accordion
        v-if="accordionData"
        :data="accordionData"
        :title="title"
        :description="subtitle"
        :background="data.style?.background_color"
        class="cms-accordion-widget-container" />
</template>
